import React from 'react'
import { pressedEnter } from 'styleguide/packages/util/index.js'

const Div = props => {
  const { role = 'button', className = '', onClick, children, ...rest } = props

  const handleKeyDown = pressedEnter(e => onClick(e))

  return (
    <div
      className={className}
      role={role}
      tabIndex={0}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      {...rest}>
      {children}
    </div>
  )
}

export default Div
